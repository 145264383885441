import { io } from "socket.io-client";

class EV54DeviceController {
  constructor(user, device) {
    this.user = user;
    this.device = device;
    this.inventory = [];
    this.callbacks = [];
    this.transactionCallbacks = [];
    this.activeAlarmsCallback = [];
    this.activeAlarms = [];
    this.replenishmentNeedsCallbacks = [];
    this.replenishmentNeeds = [];
    this.ordersCount = 0;
    this.packagedItemCallbacks = [];
    this.currentStockNeeds = [];
    this.currentStockNeedsCallbacks = [];
    //this.tabClickCallback = null;
    this._containerLockState = new Array(55).fill(0);
  }

  initializeSocket(commandCompleteCallback) {
    this.callbacks.push(commandCompleteCallback);
    const BASE_URL = process.env.REACT_APP_HOST || "";
    this.socket = io(`${BASE_URL}/ev54-client`, {
      transports: ["websocket"],
    });
    this.socket.on("connect", () => {
      // console.log(
      //     `EV54DeviceController Client Socket ${this.socket.id}  has connected`
      // );

      // let the other end know what to query for
      this.socket.emit("routing-info", {
        account: this.user.account,
        site: this.device.site,
        stockLocation: this.device,
      });

      this.socket.emit("get-active-alarms");
    });
    this.socket.on("error", (err) => {
      console.log("EV54DeviceController Client Socket error", err);
    });
    this.socket.on("disconnect", () => {
      console.log(`EV54DeviceController Client Socket has disconnected`);
      this.socket = null;
    });

    this.socket.on("active-alarm", this._handleActiveAlarm);
    this.socket.on("replenishment-needs", this._handleReplenishmentNeeds);
    this.socket.on("packaged-items", this._handlePackagedItems);
    this.socket.on("canister-for-product", this._handleCanisterForProduct);
    this.socket.on("current-stock-needs", this._handleCurrentStockNeeds);
  }

  async releaseSocket() {
    if (this.socket) {
      await this.socket.disconnect();
    }
  }

  _handleReplenishmentNeeds = (needs) => {
    this.replenishmentNeeds = needs;
    this.replenishmentNeedsCallbacks.forEach((callback) => {
      callback(needs);
    });
  };

  _handleCurrentStockNeeds = (needs) => {
    this.currentStockNeeds = needs;
    this.currentStockNeedsCallbacks.forEach((callback) => {
      callback(needs);
    });
  };

  getCurrentStockFromServer = (device) => {
    const data = {
      account: this.user.account,
      site: this.device.site,
      stockLocation: device,
    };
    this.socket.emit("get-current-stock", data);
  };

  getReplenishmentNeeds = (cb) => {
    if (cb) {
      cb(this.replenishmentNeeds);
    }
  };

  getCurrentStock = (cb) => {
    try {
      if (cb) {
        cb(this.currentStockNeeds);
      }
    } catch (e) {}
  };

  _handleActiveAlarm = (alarm) => {
    if (!this.activeAlarms.some((a) => a._id === alarm._id)) {
      this.activeAlarms.push(alarm);
      this.activeAlarmsCallback.forEach((cb) => {
        cb(alarm);
      });
    }
  };

  getActiveAlarmCount = () => {
    return this.activeAlarms.length;
  };

  getActiveAlarms = () => {
    return this.activeAlarms.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      if (b.createdAt < a.createdAt) {
        return -1;
      }
      return 0;
    });
  };

  clearReplenishmentNeed = (need) => {
    const index = this.replenishmentNeeds.findIndex(
      (o) => o.stockLocation._id === need.stockLocation._id
    );
    this.replenishmentNeeds.splice(index, 1);
    for (let i = 0; i < this.replenishmentNeedsCallbacks.length; i++) {
      this.replenishmentNeedsCallbacks[i](this.replenishmentNeeds);
    }
  };

  getReplenishmentNeedsCount = () => {
    return this.replenishmentNeeds.length;
  };

  addReplenishmentNeedsCallback = (callback, stockNeedsCallback) => {
    callback(this.replenishmentNeeds);
    this.replenishmentNeedsCallbacks.push(callback);

    if (stockNeedsCallback) {
      stockNeedsCallback(this.currentStockNeeds); // init client
      this.currentStockNeedsCallbacks.push(stockNeedsCallback);
    }
  };

  removeReplenishmentNeedsCallback = (callback, stockNeedsCallback) => {
    let index = this.replenishmentNeedsCallbacks.findIndex(callback);
    this.replenishmentNeedsCallbacks.splice(index, 1);

    if (stockNeedsCallback) {
      index = this.currentStockNeedsCallbacks.findIndex(stockNeedsCallback);
      this.currentStockNeedsCallbacks.splice(index, 1);
    }
  };

  addPackagedItemCallback = (callback) => {
    this.packagedItemCallbacks.push(callback);
  };

  removePackagedItemCallback = (callback) => {
    const index = this.packagedItemCallbacks.findIndex(callback);
    this.packagedItemCallbacks.splice(index, 1);
  };

  addActiveAlarmsCallback = (callback) => {
    callback(); // init client
    this.activeAlarmsCallback.push(callback);
  };

  removeActiveAlarmsCallback = (callback) => {
    const index = this.activeAlarmsCallback.findIndex(callback);
    this.activeAlarmsCallback.splice(index, 1);
  };

  acknowledgeAlarms = (selectedAlarms) => {
    const data = {
      user: this.user,
      alarms: selectedAlarms,
    };

    selectedAlarms.forEach((alarm) => {
      const index = this.activeAlarms.find((a) => a._id === alarm._id);
      this.activeAlarms.splice(index, 1);
    });

    this.activeAlarmsCallback.forEach((cb) => {
      cb();
    });

    this.socket.emit("acknowledge-alarms", data);
  };

  updateProduct = (product) => {
    const data = {
      product: product,
    };
    this.socket.emit("update-product", data);
  };

  getPackagedItemByRxNumber = (user, device, rxNumber) => {
    const data = {
      account: user.account,
      site: device.site,
      stockLocation: device,
      rxNumber: rxNumber,
    };
    this.socket.emit("get-packaged-item", data);
  };

  _handlePackagedItems = (data) => {
    this.packagedItemCallbacks.forEach((callback) => {
      callback(data.packagedItems);
    });
  };

  getCanisterForProduct = (user, device, product, cb) => {
    this.canisterForProductCallback = cb;
    const data = {
      account: user.account,
      site: device.site,
      stockLocation: device,
      product: product,
    };
    this.socket.emit("get-canister-for-product", data);
  };

  _handleCanisterForProduct = (data) => {
    if (this.canisterForProductCallback) {
      this.canisterForProductCallback(data.canister);
    }
  };
}

export default EV54DeviceController;
