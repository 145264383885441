import { io } from "socket.io-client";

class VBMDeviceController {
  constructor(user) {
    this.user = user;
    this.inventory = [];
  }

  async getRecords(accountId, siteId, stockLocationId) {}

  initializeSocket() {
    const BASE_URL = process.env.REACT_APP_HOST || "";
    this.socket = io(`${BASE_URL}/vbm`, {
      transports: ["websocket"],
    });
    this.socket.on("connect", () => {
      console.log(
        `Client VBMDeviceController Socket ${this.socket.id} is connected`
      );
    });
    this.socket.on("disconnect", () => {
      console.log(
        `Client VBMDeviceController Socket ${this.socket.id} has disconnected`
      );
    });
    this.socket.on("inventory-records", (data) =>
      this.handleInventoryRecords(data)
    );
    //this.socket.on("inventory-record", (data) => this.handleInventoryRecord(data));
  }

  releaseSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  getInventory = (device, callback) => {
    this.callback = callback;

    this.socket.emit("get-inventory-records", device._id);
  };

  handleInventoryRecords = (data) => {
    if (this.callback) {
      this.callback(data);
    }
  };

  // handleInventoryRecord = (data) => {
  //     if(this.callback){
  //         this.callback(data);
  //     }
  // }
}

export default VBMDeviceController;
