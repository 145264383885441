import { Button, Modal } from "antd";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorBanner from "../../../components/ErrorBanner";
import SearchBar from "../../../components/SearchBar";
import Spacer from "../../../components/Spacer";
import styles from "./DispensingOrdersDesktopView.module.css";

const DispensingOrdersDesktopView = ({
  sites,
  error,
  onError,
  getSiteHeader,
  dialogRef,
  getPendingColumn,
  getRoutedColumn,
  getPackagedColumn,
  getPackagingMethodWidget,
  getDeviceCoverageWidget,
  productFieldControllerRef,
  pendingDOSelection,
  orderDetailsDialog,
  handleOrderDetailsDialog,
  showDeviceRouteSelector,
  showFacilitySelector,
  getFacilitySelector,
  getDeviceRouteSelector,
  showGetDeviceModal,
  selectDeviceComponent,
  showPicklistModal,
  getPickList,
  showPrintMedListModal,
  getPrintMedList,
}) => {
  const { t } = useTranslation("dispensingOrders");
  const [filterText, setFilterText] = useState("");

  const handleCloseModal = () => {
    handleOrderDetailsDialog(null);
  };

  // PAGE RENDERING
  if (showDeviceRouteSelector) {
    return getDeviceRouteSelector();
  } else if (showGetDeviceModal) {
    return selectDeviceComponent();
  } else if (showPicklistModal) {
    return getPickList();
  } else if (showPrintMedListModal) {
    return getPrintMedList();
  } else if (showFacilitySelector) {
    return getFacilitySelector();
  }

  return (
    <Fragment>
      <div className={styles.view}>
        <div className={styles.header} style={{ alignContent: "center" }}>
          <h1 className={styles.title}>{t("title")}</h1>
          <SearchBar
            onSearch={(text) => {
              setFilterText(text);
            }}
            onEnter={() => {}}
            placeholder={t("searchPlaceholder")}
          />
        </div>

        <main id={"main"} className={styles.main}>
          <div className={styles.errorBannerContainer}>
            {error && (
              <ErrorBanner message={error} onClose={() => onError("")} />
            )}
          </div>
          <br />
          <br />
          <div className={styles.sites}>
            {sites.map((site, index) => {
              return getSiteHeader(site, index);
            })}
          </div>
          {getPackagingMethodWidget()}
          <Spacer space={30} unit={"px"} />
          {pendingDOSelection.length > 0 && getDeviceCoverageWidget()}
          <Spacer space={30} unit={"px"} />
          <div className={styles.siteDispensingOrderDetails}>
            {getPendingColumn(filterText)}
            {getRoutedColumn(filterText)}
            {getPackagedColumn(filterText)}
          </div>
          <Modal
            open={orderDetailsDialog !== null}
            centered={true}
            className={styles.DispensingOrdersDesktopView__modal}
            onCancel={handleCloseModal}
            title={
              <div
                style={{
                  background: "var(--green)",
                  margin: "-20px -24px -24px -24px",
                  padding: 14,
                  color: "white",
                  fontSize: "16px",
                }}
              >
                {orderDetailsDialog ? orderDetailsDialog.rxNumber : ""}
              </div>
            }
            footer={[
              <Button key="back" onClick={handleCloseModal}>
                {t("closeButton")}
              </Button>,
            ]}
          >
            <div>
              <div className="dialog-content">
                <br />
                <div className="dialogText">
                  <b>{orderDetailsDialog?.patientInfo.name}</b>
                </div>
                <br />
                <div className="dialogText">
                  {productFieldControllerRef.current.getDefaultName(
                    orderDetailsDialog?.product
                  )}
                </div>
                <br />
                <div className="dialogText">
                  {t("qtyLabel")}: {orderDetailsDialog?.qtyNeeded}
                </div>
              </div>
            </div>
          </Modal>
        </main>
      </div>
    </Fragment>
  );
};
export default DispensingOrdersDesktopView;
