import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ReceiveIcon } from "../assets/receive-icon.svg";
import HeaderForMobile from "../components/HeaderForMobile/HeaderForMobile";
import TabBar from "../components/TabBar";
import { ScreenContext } from "../contexts/ScreenContext";
import ImageService from "../services/ImageService";
import RoleCheckService from "../services/RoleCheckService";
import styles from "./Settings.module.css";

const Settings = ({ user, onLogOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [homeView, setHomeView] = useState(false);

  const { t, i18n } = useTranslation("settings");

  useEffect(() => {
    if (location.pathname === "/settings") {
      handleSelectedTab(0);
    }
  }, [location]);

  useEffect(() => {
    if (!profilePic && user.profilePic) {
      ImageService.read(user, user.profilePic).then((imageDoc) => {
        setProfilePic(imageDoc);
      });
    }
  }, [user]);

  useEffect(() => {
    switch (location.pathname) {
      case "/settings/sites":
        setHomeView(true);
        break;
      case "/settings/sites/create":
        setHomeView(false);
        break;
      case "/settings/facilities":
        setHomeView(true);
        break;
      case "/settings/facilities/create":
        setHomeView(false);
        break;
      case "/settings/facilities/deliverySchedule":
        setHomeView(false);
        break;
      case "/settings/users":
        setHomeView(true);
        break;
      case "/settings/users/create":
        setHomeView(false);
        break;
      case "/settings/sites/stock-locations":
        setHomeView(false);
        break;
      case "/settings/account-settings":
        setHomeView(true);
        break;

      default:
      // code block
    }
  }, [location.pathname]);

  useEffect(() => {
    const canViewPharmacySettings = RoleCheckService.viewPharmacySettings(user);
    const canViewFacilitySettings = RoleCheckService.viewFacilitySettings(user);
    const canViewUserSettings = RoleCheckService.viewUserSettings(user);

    const tempTabs = [];

    tempTabs.push({
      index: 0,
      icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      text: t("accountTab"),
    });

    if (canViewPharmacySettings) {
      tempTabs.push({
        index: 1,
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
        text: t("pharmaciesTab"),
      });
    }
    if (canViewFacilitySettings) {
      tempTabs.push({
        index: 2,
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
        text: t("facilitiesTab"),
      });
    }
    if (canViewUserSettings) {
      tempTabs.push({
        index: 3,
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
        text: t("usersTab"),
      });
    }
    setTabs(tempTabs);
  }, [user, i18n.language]);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const handleSelectedTab = (index) => {
    if (index === 0) {
      navigate("account-settings");
    } else if (index === 1) {
      navigate("sites");
    } else if (index === 2) {
      navigate("facilities", { state: { profilePic: profilePic } });
    } else if (index === 3) {
      navigate("users", { state: { profilePic: profilePic } });
    }
  };

  return (
    <main className={styles.settings__main}>
      {smScreen ? (
        <>
          {location.pathname === "/settings/account-settings" && (
            <HeaderForMobile user={user} onLogOut={onLogOut} />
          )}
          {homeView && (
            <TabBar
              tabs={tabs}
              onSelectedTab={handleSelectedTab}
              fontSize={"14px"}
            />
          )}
        </>
      ) : (
        <section className={styles.settings__headerRow}>
          <div className={styles.settings__tabBar}>
            <TabBar
              tabs={tabs}
              onSelectedTab={handleSelectedTab}
              fontSize={"14px"}
            />
          </div>
          {/*{!smScreen && <AlarmDateTime />}*/}
        </section>
      )}
      <Outlet />
      {/*{selectedTab.index === 0 && <PharmacySites user={user} />}*/}
      {/*{selectedTab.index === 1 && <Facilities user={user} />}*/}
      {/*{selectedTab.index === 2 && <Users user={user} />}*/}
      {/*{selectedTab.index === 3 && <Products user={user} alarm={alarm} />}*/}
    </main>
  );
};

export default Settings;
