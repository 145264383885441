import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./Inventory.module.css";

const Inventory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("inventory");
  const [tabItems, setTabItems] = useState([]);
  const [activeKey, setActiveKey] = useState("items");

  useEffect(() => {
    if (location.pathname === "/inventory") {
      navigate("items");
    }
    if (location.pathname.startsWith("/inventory/items")) {
      setActiveKey("items");
    } else if (location.pathname.startsWith("/inventory/substitutions")) {
      setActiveKey("substitutions");
    } else if (location.pathname.startsWith("/inventory/medications")) {
      setActiveKey("medications");
    }
  }, [location]);

  useEffect(() => {
    setTabItems([
      {
        key: "items",
        label: t("canisterInventoryTab"),
        children: <Outlet />,
      },
      {
        key: "substitutions",
        label: t("substitutionsTab"),
        children: <Outlet />,
      },
      {
        key: "medications",
        label: t("medicationTab"),
        children: <Outlet />,
      },
    ]);
  }, [i18n.language]);

  const onChange = (key) => {
    navigate(key);
  };

  return (
    <Tabs
      className={styles.inventoryTabs}
      defaultActiveKey="items"
      activeKey={activeKey}
      items={tabItems}
      onChange={onChange}
    />
  );
};

export default Inventory;
