import { io } from "socket.io-client";
const FillListService = require("./FillListService");

class FillListController {
  constructor(user) {
    this.user = user;
  }

  initializeSocket() {
    const BASE_URL = process.env.REACT_APP_HOST || "";
    this.socket = io(`${BASE_URL}/inventory`, {
      transports: ["websocket"],
    });
    this.socket.on("connect", () => {
      console.log(`Client Fill List Socket ${this.socket.id} is connected`);
    });
    this.socket.on("disconnect", () => {
      console.log(`Socket ${this.socket.id} has disconnected`);
    });
    this.socket.on("sites", (data) => this.sitesListener(this, data));
    this.socket.on("fill-list-by-product", this.handleFillListByProduct);
  }

  releaseSocket() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  getSites(sitesCallback) {
    this.sitesCallback = sitesCallback;
    if (this.socket) {
      const data = {
        account: this.user.account,
      };
      this.socket.emit("get-sites", data);
    }
  }

  // SOCKET LISTENERS
  sitesListener(sender, data) {
    sender.sitesCallback(data.sites);
  }

  // stockLocation: a device
  calculateFillListByProduct = (stockLocation, callback) => {
    this._fillListCallback = callback;
    const data = {
      stockLocation: stockLocation,
    };
    this.socket.emit("calculate-fill-list-by-product", data);
  };

  handleFillListByProduct = (data) => {
    /*

        data = [
            {
                product: {},
                needQty: ,
                stockItems: [], // internal canisters
                externalStockedItems: [] // known locations of stocked item bottles, etc
            }
        ]
        */
    if (this._fillListCallback) {
      this._fillListCallback(data);
    }
  };

  getCanisterReplenishmentData = async (callback) => {
    FillListService.default
      .getCanisterReplenishmentData(this.user)
      .then((results) => {
        callback(null, results);
      })
      .catch((error) => {
        callback(error);
      });
  };
}

export default FillListController;
